import React, { useEffect, useState } from "react";
import { deleteMatkaAPI, getAllMatkaAPI } from "../../service/matka.service";
import { Row } from "react-bootstrap";
import moment from "moment";
import toast from "react-hot-toast";

export default function MatkaList() {
  const [data, setData] = useState([]);
  const getAllMatka = async () => {
    const { response } = await getAllMatkaAPI();
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    getAllMatka();
  }, []);
  const handleDelete = async (id) => {
    try {
      const { response, code } = await deleteMatkaAPI(id);
      if (code === 200) {
        toast.success("Matka deleted successfully");
        getAllMatka();
      } else {
        toast.error(response);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px",
        }}
      >
        <h2 style={{ width: "auto", marginRight: "auto" }}>Matka List</h2>
        <button
          onClick={() => {
            window.location.href = "/dashboard/matka/create";
          }}
          style={{ width: "auto" }}
          className="btn btn-primary"
        >
          Create Matka
        </button>
        <button
          onClick={() => {
            window.location.href = "/dashboard/matka-market";
          }}
          style={{ width: "auto" }}
          className="btn btn-primary"
        >
          Market List
        </button>
      </Row>

      <table style={{ color: "white" }} className="table table-bordered light">
        <thead>
          <tr>
            <th>Matka Name</th>
            <th>Open Time</th>
            <th>Close Time</th>
            <th>Result Time</th>
            <th>Min bet</th>
            <th>Max bet</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.openTime}</td>
              <td>{item.closeTime}</td>
              <td>{item.resultTime}</td>
              <td>{item.minStack}</td>
              <td>{item.maxStack}</td>
              <td>
                <button
                  onClick={() => {
                    window.location.href = `/dashboard/matka/${item.id}`;
                  }}
                  className="btn btn-primary"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(item?.id)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
